import React from "react";

const Description = ({ text, iconImage }) => {
	return (
		<div className="desc">
			<span dangerouslySetInnerHTML={{ __html: text }} className={iconImage ? "" : "full"} />
			{iconImage && <img alt="Betway Logo" className="icon" src="/static/images/logo/icon_app.png" srcset="/static/images/logo/icon_app.png 1x, /static/images/logo/icon_app@2x.png 2x, /static/images/logo/icon_app@3x.png.png 3x" />}
		</div>
	);
};

export default Description;
