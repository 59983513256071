import React from 'react';

const Central = ({ title, children }) => {
	return (
		<section className="central">
			<div className="title">
				<h3>{title}</h3>
			</div>
			{children}
		</section>
	)
}

export default Central