/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-24 16:41:49
 * @FilePath: \P1-M1-QRP-CODE\components\style10\RegisterForm.js
 */
import React, { useMemo } from 'react';
import { Button } from 'antd';
import { RegName, RegPassword, RegPhone, RegUserid } from '$CONFIG/common/reg';
import { useForm } from "react-hook-form";
import { setupIsHideField } from "$UTILS/helpers/QrpFields";
import useQrpFields from "$UTILS/hooks/useQrpFields";
import useRegister from '$UTILS/hooks/useRegister';
import { getBirthdayYears, getBirthdayDays , getBirthDayMonth} from '$UTILS';
import Loading from '@/Loading';
import {getAffFromCookie} from "../../actions/util";

const years = getBirthdayYears();

let oldValues = {}; //用於判斷是否提交piwik

const piwikMapping = {
	username: 'Username_input',
	password: 'Password_input',
	mobile: 'phone_input',
	documentId: 'cnId_input'
}

const RegisterForm = ({ onBefore, onSuccess, onError, cssStyleConf={} }) => {
	let {isHideDOCID, isHideGENDER, isHideDOB} = setupIsHideField(useQrpFields)
	console.log('style10', isHideDOCID, isHideGENDER, isHideDOB)

	const affiliateCode = getAffFromCookie();

	const { loading, run } = useRegister({ onBefore, onSuccess, onError });
	const { register, getValues, trigger, handleSubmit, formState: { errors }, watch, setValue } = useForm({
		mode: 'all',
		defaultValues: {
			birthday: {
				year: '',
				month: '',
				day: ''
			},
			gender: 'Male'
		}
	});
	const currentYear = watch('birthday.year');
	const currentMonth = watch('birthday.month');
	const days = useMemo(() => getBirthdayDays(currentYear, currentMonth), [currentYear, currentMonth]);
	const month = useMemo(() =>getBirthDayMonth(currentYear, currentMonth), [currentYear, currentMonth]);
	const handleFormSubmit = data => {
		console.log("data", data)
		const { username, password, mobile, birthday, documentId, gender } = data;
		run({...{
			username,
			password,
			mobile: `${mobile}`,
			gender,
			affiliateCode,
		},
		...(isHideDOCID ? {} : {documentId}),
		...(isHideGENDER ? {} : {gender}),
		...(isHideDOB ? {} : {dateOfBirth: `${birthday.year}-${birthday.month}-${birthday.day}`}),
		}, {isHideDOCID, isHideGENDER, isHideDOB})
	}

	//focus時，存下當前數值
	const handleFocus = (fieldName) => {
		const currentVal = getValues(fieldName);
		console.log(`${fieldName} onFocus with ${currentVal}`);
		oldValues[fieldName] = currentVal;
	}

	//blur時，從之前存下的值，判斷是否有變更
	const handleBlurPiwik = (fieldName) => {
		const oldVal = oldValues[fieldName];
		const currentVal = getValues(fieldName);
		if (currentVal && (currentVal != oldVal)) { //要有數值+有變更才需要提交piwik
			console.log(`${fieldName} REALBlur from ${oldVal} to ${currentVal}`)
			const eventName = piwikMapping[fieldName];
			global.globalGtag('Style10_QRP', 'Registration form', eventName, 'input')
		}
	}

	//驗證身分證號和生日要符合
	const validateIdAndBirthday = (v) => {
		if (v && v.length >= 15) {
			let bday = getValues('birthday.year') + getValues('birthday.month') + getValues('birthday.day');
			console.log('===bday', bday, v, v.length);
			if (v.length == 18) {
				return (bday == v.substr(6, 8));
			} else if (v.length == 15) {
				bday = bday.substr(2);
				return (bday == v.substr(6, 6));
			}
		}
		return false;
	}

	//修改生日時 觸發驗證身分證號
	const validateDocumentId = () => {
		let id = getValues('documentId');
		let bday = getValues('birthday.year') + getValues('birthday.month') + getValues('birthday.day');
		//都有數據才觸發documentid驗證
		if (bday.length === (4+2+2) && id.length >= 15) {
			trigger('documentId');
		}
	}

	let cssStyle = {};
	if (cssStyleConf?.isFullWidthSubmit) {
		cssStyle.width = '100%';
	}

	return (
		<form className="form-wrapper" onSubmit={handleSubmit(handleFormSubmit)}>
			<div className="form-block">
				<div className="login-item grid-cols-4">
					<label className="form-label">用户名</label>
					<input type="text" className="input-control col-span-3" maxLength={14} placeholder="请输入用户名"
								 onFocus={() => handleFocus('username')}
								 {...register("username", {
									 required: "用户名不可为空", pattern: { value: RegName, message: "用户名必须由 6 － 14 位长度的字母或数字组成，中间不得有读音符号、空格或下划线。" }
									 , onBlur: () => handleBlurPiwik('username')
								 })} />
					{errors.username && <label className="error-txt col-span-3 col-start-2">{errors.username.message}</label>}
				</div>
				<div className="login-item grid-cols-4">
					<label className="form-label">密码</label>
					<input type="password" className="input-control col-span-3" maxLength={20} name="password" placeholder="请输入密码"
								 onFocus={() => handleFocus('password')}
								 {...register("password", {
									 required: "密码不可为空", pattern: { value: RegPassword, message: '密码必须包含6-14个字符，字符只限于使用字母和数字。（可以包含 ^#$@ 中的特殊字符）' }
									 , onBlur: () => handleBlurPiwik('password')
								 })} />
					{errors.password && <label className="error-txt col-span-3 col-start-2">{errors.password.message}</label>}
				</div>
				<div className="login-item grid-cols-4">
					<label className="form-label">手机号码</label>
					<input disabled type="tel" className="input-control disabled" name="phone" value={'+86'}></input>
					<input type="text" className="input-control col-span-2" maxLength={11} name="mobile" id="inputPhone" placeholder="请输入手机号码"
								 onFocus={() => handleFocus('mobile')}
								 {...register("mobile", {
									 required: "手机号码不可为空", pattern: { value: RegPhone, message: '手机号码必须是 11 位长度的数字。' }
									 , onBlur: () => handleBlurPiwik('mobile')
								 })} />
					{errors.mobile && <label className="error-txt col-span-2 col-start-3">{errors.mobile.message}</label>}
				</div>
				{isHideDOB ? null : <div className="login-item grid-cols-4" data-key="DOB">
					<label className="form-label">出生日期</label>
					<select className="input-control birthday" {...register("birthday.year", { required: true, onChange: () => { setValue("birthday.day", ""); validateDocumentId(); global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayYear_input', 'input') } })}>
						<option value="" disabled>年</option>
						{years.map(value => <option key={value} value={value}>{value}</option>)}
					</select>
					<select className="input-control birthday" {...register("birthday.month", { required: true, onChange: () => { setValue("birthday.day", ""); validateDocumentId(); global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayMonth_input', 'input') } })}>
						<option value="" disabled>月</option>
						{month.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
					</select>
					<select className="input-control birthday" {...register("birthday.day", { required: true, onChange: () => { validateDocumentId(); global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayDay_input', 'input') } })} >
						<option value="" disabled>日</option>
						{days.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
					</select>
					{errors.birthday && <label className="error-txt col-span-3 col-start-2">出生日期不可为空。</label>}
				</div>}
				{isHideDOCID ? null : <div className="login-item grid-cols-4" data-key="DOCID">
					<label className="form-label">身份证号</label>
					<input type="text" className="input-control col-span-3" maxLength={18} placeholder="请输入身份证号"
								 onFocus={() => handleFocus('documentId')}
								 {...register("documentId", {
									 required: "身份证号不可为空", pattern: { value: RegUserid, message: '身份证号格式无效。' }
									 , validate:  v => validateIdAndBirthday(v) || '您的身份证号码与生日不符'
									 , onBlur: () => handleBlurPiwik('documentId')
								 })} />
					{errors.documentId && <label className="error-txt col-span-3 col-start-2">{errors.documentId.message}</label>}
				</div>}
				{isHideGENDER ? null : <div className="login-item grid-cols-4" data-key="GENDER">
					<label className="form-label">性別</label>
					<select className="input-control col-span-3" id="inputState" name="gender" {...register("gender", { required: true, onChange: () => { global.globalGtag('Style10_QRP', 'Registration form', 'gender_input', 'input') } })} >
						<option value="Male">男</option>
						<option value="Female">女</option>
					</select>
				</div>}
				<Button style={cssStyle} htmlType='submit' className="submit" onClick={() => { global.globalGtag('Style10_QRP', 'Registration form', 'Submit_registration', 'event') }} disabled={loading}>提交</Button>
				{loading && <Loading />}
			</div>
		</form>
	)
}

export default RegisterForm
